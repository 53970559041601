import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CarItem from '../../CarItem';
import s from './BestPriceItem.css';
import { parseServices } from '../helpers';

const BestPriceItem = ({ car, ...props }) => {
  const services = props.services || car.services;

  const { labelBestPrice, labelSuperPrice } = parseServices(services);

  return (
    <div className={s.root}>
      <div className={s.title}>
        {labelBestPrice && 'Краща ціна'}
        {labelSuperPrice && 'Cупер ціна'}
      </div>
      <CarItem car={car} services={services} />
    </div>
  );
};

BestPriceItem.propTypes = {
  car: PropTypes.shape().isRequired,
  services: PropTypes.arrayOf(PropTypes.shape()),
};

BestPriceItem.defaultProps = {
  services: null,
};

export default withStyles(s)(BestPriceItem);
