import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withLocalize } from 'locales';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { renderField } from 'components/AddCarForm/renderers';
import PhoneField from 'components/Form/PhoneField';

import validate from './validate';
import s from './PhoneAuthForm.css';
import messages from './messages';
import { WRONG_CODE_STATUS } from './constants';

const RETRY_TRESHOLD = 2 * 60 * 1000; // 2 mins

class PhoneAuthForm extends React.Component {
  static contextTypes = {
    fetch: PropTypes.func.isRequired,
  };

  static propTypes = {
    authPhone: PropTypes.func.isRequired,
    authError: PropTypes.func.isRequired,
    next: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.shape().isRequired,
    ctaTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ctaClassName: PropTypes.string,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ctaTitle: null,
    ctaClassName: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      codeSent: false,
    };
  }

  componentWillUnmount() {
    this.props.authError(null);
  }

  submit = values => {
    const { authPhone } = this.props;
    const { number: { number }, code } = values;
    const { localize } = this.props;
    const { codeSent } = this.state;
    // code required validation
    if (this.codeSent() && !code) {
      throw new SubmissionError({
        code: localize('Будь-ласка, введіть код підтвердження'),
      });
    }
    if (codeSent && !code && !this.codeSent()) {
      throw new SubmissionError({
        code: localize('Повторна відправка буде доступна протягом 2 хвилин'),
      });
    }

    authPhone(this.context.fetch, { number, code }, this.props.next);
    if (!code) {
      this.setState({ codeSent: true });
      setTimeout(() => {
        this.setState({ codeSent: false });
      }, RETRY_TRESHOLD);
    }
  };

  codeSent = () => {
    const { auth: { errors } } = this.props;
    return errors && errors.code && errors.code[0] !== WRONG_CODE_STATUS;
  };

  showCode = () => {
    const { auth: { errors } } = this.props;
    return (
      this.codeSent() ||
      (errors && errors.code && errors.code[0] === WRONG_CODE_STATUS)
    );
  };

  renderErrors = () => {
    const { auth: { errors }, localize } = this.props;
    let result = [];
    if (errors && errors.code && !this.codeSent(errors)) {
      result = [...result, localize('Неправильний код підтвердження')];
    }

    if (errors && errors.non_field_errors) {
      result = [...result, ...errors.non_field_errors];
    }

    if (result.length > 0) {
      return result.map(err => (
        <div className="alert alert-danger" key={err}>
          {err}
        </div>
      ));
    }
    return null;
  };

  render() {
    const { handleSubmit, ctaTitle, ctaClassName, localize } = this.props;
    return (
      <div>
        <form
          method="post"
          onSubmit={handleSubmit(this.submit)}
          autoComplete="on"
          name="loginForm"
        >
          {this.renderErrors()}
          <Field
            placeholder={localize('Номер телефону')}
            type="text"
            name="number"
            component={PhoneField}
          />
          <div className={`form-group${!this.showCode() ? ' d-none' : ''}`}>
            <div className={`alert alert-warning ${s.sent_text}`}>
              <FormattedMessage
                id="login.phone.sms.descr"
                defaultMessage="Повідомлення з кодом підтвердження відправлено на цей номер"
                description=""
              />
            </div>
            <Field
              placeholder={localize('Код з SMS')}
              type="text"
              name="code"
              component={renderField}
            />
          </div>
          <div className={s.formGroup}>
            <button
              className={cn('btn', 'btn-primary', s.button, ctaClassName)}
              type="submit"
            >
              {ctaTitle || localize('Увійти')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withLocalize(messages)(
  withStyles(s)(
    reduxForm({
      form: 'phoneAuth',
      validate,
    })(PhoneAuthForm),
  ),
);
