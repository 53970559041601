import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import CarItem from '../../CarItem';
import s from './DayBestItem.css';

class DayBestItem extends React.Component {
  static propTypes = {
    car: PropTypes.shape().isRequired,
    services: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    services: null,
  };

  render() {
    const { car } = this.props;
    const services = this.props.services || car.services;

    return (
      <div className={s.root}>
        <div className={s.title}>
          <FormattedMessage
            id="services.day_best"
            defaultMessage="Оголошення дня"
            description=""
          />
        </div>
        <CarItem car={car} services={services} />
      </div>
    );
  }
}

export default withStyles(s)(DayBestItem);
