import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'reactstrap';
import { withLocalize } from 'locales';

import Preview from '../Preview';
import s from './ProposalDescription.css';
import messages from '../messages';

const renderText = (qty, localize) => {
  if (qty === 1) {
    return localize('рекламна послуга');
  } else if (qty < 5 && qty > 1) {
    return localize('рекламні послуги');
  }
  return localize('рекламних послуг');
};

const ProposalDescription = ({ offer, service, localize }) => {
  const { meta: { description, properties }, price, period } = service;
  return (
    <>
      <Row>
        <Col xs="12">
          <p>
            послуга надається{' '}
            <strong>
              {period.days > 360
                ? 'до остаточного продажу авто'
                : `на ${period.title}`}
            </strong>
          </p>
          <p>
            {localize('Оголошення')}:{' '}
            <strong>
              ID {offer.id} - {offer.title} - {offer.price} $
            </strong>
          </p>
        </Col>
      </Row>
      <div className={s.preview}>
        <Preview offer={offer} service={service} />
      </div>
      <Row>
        <Col xs={12}>
          <div>
            <p>
              {localize('Сума до сплати')}:{' '}
              <strong>
                {price} {localize('грн')}.
              </strong>
            </p>
          </div>
          <p>{description}</p>
          <Row>
            <Col xs="12" md="6">
              <ul>
                {properties.map(item => <li key={item.slug}>{item.title}</li>)}
              </ul>
            </Col>
            <Col xs="12" md="6" className={s.mttext}>
              <p className={s.count}>{properties.length}</p>
              <p className={s.text}>
                {renderText(properties.length, localize)}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ProposalDescription.propTypes = {
  offer: PropTypes.shape().isRequired,
  service: PropTypes.shape().isRequired,
  localize: PropTypes.func.isRequired,
};

export default withStyles(s)(withLocalize(messages)(ProposalDescription));
