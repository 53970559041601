import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col, Button } from 'reactstrap';
import { withLocalize } from 'locales';

import s from './LiqPay.css';
import messages from './messages';

/* eslint-disable react/no-danger */

class LiqPay extends React.Component {
  static propTypes = {
    data: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    title: PropTypes.string,
    autosubmit: PropTypes.bool,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: null,
    autosubmit: false,
  };

  componentDidMount() {
    const { autosubmit } = this.props;
    if (autosubmit) {
      document.getElementById('id_liqpay_form').submit();
    }
  }

  render() {
    const { data, signature, title, localize } = this.props;
    return (
      <Row className={s.root}>
        <Col>
          <form
            id="id_liqpay_form"
            method="POST"
            action="https://www.liqpay.ua/api/3/checkout"
          >
            <input type="hidden" name="data" value={data} />
            <input type="hidden" name="signature" value={signature} />
            <Button color="success" outline>
              {title || localize('Оплатити')}
            </Button>
          </form>
        </Col>
      </Row>
    );
  }
}

export default withLocalize(messages)(withStyles(s)(LiqPay));
