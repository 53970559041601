import { defineMessages } from 'react-intl';

export default defineMessages({
  Увійти: {
    id: 'loginForm:enter:btn',
    defaultMessage: 'Увійти',
  },
  'Ваш Email': {
    id: 'loginForm:email:ph',
    defaultMessage: 'Ваш Email',
  },
  Пароль: {
    id: 'loginForm:password:ph',
    defaultMessage: 'Пароль',
  },
  'Будь-ласка, вкажіть Ваш email': {
    id: 'loginForm:email:error',
    defaultMessage: 'Будь-ласка, вкажіть Ваш email',
  },
  'Будь-ласка, вкажіть пароль': {
    id: 'loginForm:password:error',
    defaultMessage: 'Будь-ласка, вкажіть пароль',
  },
});
