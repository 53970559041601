exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Roboto\",sans-serif;--link-color:#333;--link-color-ligther:rgba(51,51,51,.7);--link-hover-color:rgba(27,199,68,.9);--ap-orange-color:#ffaa4d;--text-white-stroke-shadow:-1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;--text-black-stroke-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;--max-content-width:1400px;--backgroud-body:#f9f9f9;--border-main-style:1px solid #eee;--main-box-shadow:0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.08);--item-box-shadow:0 0 4px 1px rgba(0,0,0,.08);--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--list-item-label-color:#1bc744;--price-background-color:#1bc744;--service-box-shadow:0 0 3.2px 3.2px rgba(51,51,51,.2);--service-box-shadow:0 0 0.2rem 0.2rem rgba(51,51,51,.2)}._3UMQc{color:#ffaa4d;color:var(--ap-orange-color);text-shadow:0 0 10px rgba(0,0,0,.2)}._2kS1t{font-size:64px;font-size:4rem;margin:0}._2kS1t,.C1I-A{text-align:center}.C1I-A{font-size:24px;font-size:1.5rem;font-weight:500}._2xwfA{margin:0 -24px;margin:0 -1.5rem}@media screen and (min-width:1200px){._2xwfA{margin:0 auto;max-width:80%}}", ""]);

// exports
exports.locals = {
	"mttext": "_3UMQc",
	"count": "_2kS1t",
	"text": "C1I-A",
	"preview": "_2xwfA"
};