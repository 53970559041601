import { defineMessages } from 'react-intl';

export default defineMessages({
  Увійти: {
    id: 'authPhone:enter:btn',
    defaultMessage: 'Увійти',
  },
  'Номер телефону': {
    id: 'authPhone:number:ph',
    defaultMessage: 'Номер телефону',
  },
  'Код з SMS': {
    id: 'authPhone:code:ph',
    defaultMessage: 'Код з SMS',
  },
  'Вкажіть, будь-ласка, правильний номер телефону': {
    id: 'authPhone:phone_field:error',
    defaultMessage: 'Вкажіть, будь-ласка, правильний номер телефону',
  },
  'Будь-ласка, вкажіть номер телефону': {
    id: 'authPhone:phone_field:required',
    defaultMessage: 'Будь-ласка, вкажіть номер телефону',
  },
  'Неправильний код підтвердження': {
    id: 'authPhone:code_field:error',
    defaultMessage: 'Неправильний код підтвердження',
  },
  'Будь-ласка, введіть код підтвердження': {
    id: 'authPhone:code_field:required',
    defaultMessage: 'Будь-ласка, введіть код підтвердження',
  },
  'Повторна відправка буде доступна протягом 2 хвилин': {
    id: 'authPhone:code_field:retry:error',
    defaultMessage: 'Повторна відправка буде доступна протягом 2 хвилин',
  },
});
