import React from 'react';
import Layout from 'components/Layout';
import PaymentResult from 'components/PaymentResult';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';

import { makeLocalize } from 'locales';
import messages from './messages';

function action({ intl, query, fetch, store }) {
  const localize = makeLocalize(intl, messages);
  store.dispatch(setBreadcrumbs([]));

  const { id: invoiceId } = query;
  const title = localize('Дякуємо! Ми вже опрацьовуємо вашу оплату!');

  return {
    title: `${title} - Avtoprod.UA`,
    chunks: ['core'],
    component: (
      <Layout>
        <PaymentResult invoiceId={invoiceId} fetch={fetch} />
      </Layout>
    ),
  };
}

export default action;
