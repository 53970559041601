import React from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import phoneStyles from 'react-intl-tel-input/dist/main.css';

class PhoneField extends React.Component {
  static propTypes = {
    input: PropTypes.shape().isRequired,
    defaultCountry: PropTypes.string,
    onlyCountries: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.string,
    }).isRequired,
    // required: PropTypes.bool,
  };

  static defaultProps = {
    onlyCountries: ['ua', 'pl', 'lt', 'de', 'no'],
    defaultCountry: 'ua',
    // required: false,
  };

  render() {
    const {
      input: { value, onChange, onBlur, name },
      defaultCountry,
      onlyCountries,
      meta: { touched, error },
    } = this.props;
    const hasError = touched && error;
    const number = value.number || '+380';

    return (
      <div className="form-group">
        <IntlTelInput
          style={{ width: '100%' }}
          value={number}
          defaultCountry={defaultCountry}
          fieldName={name}
          autoComplete="phone"
          nationalMode={false}
          onlyCountries={onlyCountries}
          preferredCountries={['ua']}
          onPhoneNumberChange={(v, n) => onChange({ valid: v, number: n })}
          onPhoneNumberBlur={(v, n) => onBlur({ valid: v, number: n })}
          containerClassName="intl-tel-input"
          inputClassName={`form-control${hasError ? ' is-invalid' : ''}`}
          // utilsScript="assets/libphonenumber.js"
        />
        {hasError && <small className="text-danger">{error}</small>}
      </div>
    );
  }
}

export default withStyles(phoneStyles)(PhoneField);
