import { defineMessages } from 'react-intl';

export default defineMessages({
  'Замовлені послуги та оплати': {
    id: 'personal:services:seo_title',
    defaultMessage: 'Замовлені послуги та оплати',
  },
  'Дякуємо! Ми вже опрацьовуємо вашу оплату!': {
    id: 'personal:result:seo_title',
    defaultMessage: 'Дякуємо! Ми вже опрацьовуємо вашу оплату!',
  },
});
