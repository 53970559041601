import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Icon } from 'react-icons-kit';
import { smileO } from 'react-icons-kit/fa/smileO';
import { frownO } from 'react-icons-kit/fa/frownO';

import { invoiceRequest } from 'actions/paymentActions';
import LoadingBar from 'components/LoadingBar';
import Panel from 'components/Helpers/Panel';
import Link from 'components/Link';
import * as GA from 'ga';

import s from './PaymentResult.css';

const MAX_ATTEMPTS = 3;
const ATTEMPT_INTERVAL = 2;

const Done = ({ success, isPdr }) => (
  <div className={cn({ [s.success]: success, [s.fail]: !success })}>
    <Icon size="50%" icon={success ? smileO : frownO} />
    <p>
      {success ? (
        <>
          <p className={s.success_title}>
            <FormattedMessage
              id="payment.result.success_text"
              defaultMessage="Оплата за послуги пройшла успішно"
              description=""
            />
          </p>
          <p>
            <FormattedMessage
              id="payment.result.success_text2"
              defaultMessage="Дякуємо, що обрали Автопрод!"
              description=""
            />
          </p>
          <div className={s.next}>
            <Link to={isPdr ? '/pdr/' : '/'} className="btn btn-success">
              <FormattedMessage
                id="payment.result.btn"
                defaultMessage="Почати"
                description=""
              />
            </Link>
          </div>
        </>
      ) : (
        <>
          <p className={s.fail_title}>
            <FormattedMessage
              id="payment.result.fail_title"
              defaultMessage="На жаль, нам не вдалось отримати інформацію про ваш платіж."
              description=""
            />
          </p>
          <p>
            <FormattedMessage
              id="payment.result.fail_text"
              defaultMessage="Не хвилюйтесь, як тільки ми отримаємо інформацію про оплату - обов'язково активуємо послуги."
              description=""
            />
          </p>
          <p>
            <FormattedMessage
              id="payment.result.fail_text2"
              defaultMessage="Ми завжди раді відповісти на всі Ваші запитання в месенджерах Telegram або Viber."
              description=""
            />
          </p>
          <p>
            <FormattedMessage
              id="payment.result.fail_text3"
              defaultMessage="Або відправте нам листа"
              description=""
            />{' '}
            <a href="mailto:help@avtoprod.ua">help@avtoprod.ua</a>
          </p>
          <div className={s.next}>
            <Link to="/" className="btn btn-danger">
              <FormattedMessage
                id="payment.result.btn.fail"
                defaultMessage="Повернутись на головну сторінку"
                description=""
              />
            </Link>
          </div>
        </>
      )}
    </p>
  </div>
);

Done.propTypes = {
  success: PropTypes.bool,
  isPdr: PropTypes.bool,
};

Done.defaultProps = {
  success: false,
  isPdr: false,
};

const gtagReportConversion = ({ url, amount }) => {
  const gtag = window.gtag || (() => null);

  gtag('event', 'conversion', {
    send_to: 'AW-799725214/FwJdCOb0utADEJ6tq_0C',
    value: amount,
    currency: 'UAH',
    transaction_id: '',
    event_callback: () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    },
  });
  return false;
};

const PaymentResult = ({ invoiceId, fetch }) => {
  const [attempt, setAttempt] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const [invoice, setInvoice] = React.useState(null);

  const isPdr = invoice && invoice.amount === '149.00';

  React.useEffect(
    () => {
      if (done) {
        return () => null;
      }
      if (attempt < MAX_ATTEMPTS) {
        (async () => {
          try {
            const invoiceData = await invoiceRequest(fetch, invoiceId);
            if (!invoiceData) {
              setDone(true);
            } else if (invoiceData.payed) {
              setInvoice(invoiceData);
              setDone(true);
            } else {
              setTimeout(() => {
                setAttempt(attempt + 1);
              }, ATTEMPT_INTERVAL * 1000);
            }
          } catch (err) {
            console.error(err);
            setDone(true);
          }
        })();
      } else {
        setDone(true);
      }
      return () => null;
    },
    [attempt, done],
  );

  React.useEffect(
    () => {
      if (done) {
        if (invoice && invoice.payed) {
          const amount = parseInt(invoice.amount, 10);
          GA.offerPayedEvent(invoice.id, amount);
          gtagReportConversion({ amount });
        } else {
          GA.offerFailPayment(invoiceId);
        }
      }
    },
    [invoice, done],
  );

  return (
    <Panel className={s.root}>
      {done ? (
        <Done success={!!invoice} isPdr={isPdr} />
      ) : (
        <LoadingBar text="Отримуємо дані про Вашу оплату!" />
      )}
    </Panel>
  );
};

PaymentResult.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default withStyles(s)(PaymentResult);
