import phoneValidate from 'components/Form/PhoneField/validate';

const validate = (values, { localize }) => {
  const errors = {};
  if (!values.number) {
    errors.number = localize('Будь-ласка, вкажіть номер телефону');
  } else {
    const phoneNumberError = phoneValidate(values.number, { localize });
    if (phoneNumberError) {
      errors.number = phoneNumberError;
    }
  }

  return errors;
};

export default validate;
