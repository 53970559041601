exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1e84c{margin:auto;min-height:60vh;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:40px 10%!important;text-align:-webkit-center;margin-bottom:20px}._2dKy8 i{color:#28a745}.U271U{color:#28a745!important;font-size:20px;font-size:1.25rem;font-weight:300}.erfZD i{color:#dc3545}._21Vff{color:#dc3545!important;font-size:20px;font-size:1.25rem;font-weight:300}._1K4j_{padding:15px}", ""]);

// exports
exports.locals = {
	"root": "_1e84c",
	"success": "_2dKy8",
	"success_title": "U271U",
	"fail": "erfZD",
	"fail_title": "_21Vff",
	"next": "_1K4j_"
};