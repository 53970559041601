import { connect } from 'react-redux';

import PhoneAuthForm from 'components/Login/PhoneAuthForm';
import { authPhone, authError } from 'actions/authActions';

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { authPhone, authError })(
  PhoneAuthForm,
);
