import { connect } from 'react-redux';

import LoginForm from 'components/Login/LoginForm';
import { authLogin } from 'actions/authActions';

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { authLogin })(LoginForm);
