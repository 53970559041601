import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'reactstrap';

import LoginForm from 'containers/LoginForm';
import PhoneAuthForm from 'containers/PhoneAuthForm';

import s from './Login.css';
import Link from '../Link';

class Login extends React.Component {
  static contextTypes = {
    fetch: PropTypes.func.isRequired,
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isPhoneLogin: true,
    };
  }

  toggleMode = () => {
    this.setState({ isPhoneLogin: !this.state.isPhoneLogin });
  };

  toggleModeLink = () => (
    <Button
      color="link"
      size="xs"
      onClick={this.toggleMode}
      className={s.heading_button}
    >
      {this.state.isPhoneLogin ? (
        <FormattedMessage
          id="login.with_password"
          defaultMessage="увійти з email?"
          description=""
        />
      ) : (
        <FormattedMessage
          id="login.with_phone"
          defaultMessage="увійти з номером телефону?"
          description=""
        />
      )}
    </Button>
  );

  render() {
    const { next } = this.props;
    const { isPhoneLogin } = this.state;

    return (
      <Row className={s.root}>
        <Col xs="12" md={{ size: 4, offset: 4 }}>
          <h1 className={s.heading}>
            {this.props.title} {this.toggleModeLink()}
          </h1>
          {isPhoneLogin ? (
            <div>
              <p className={s.hint}>
                <FormattedMessage
                  id="login.heading.phone.descr"
                  defaultMessage="Ви вперше на avtoprod.ua? - введіть номер телефону і ми створимо акаунт для Вас автоматично"
                  description=""
                />
              </p>
              <p className={s.lead}>
                <FormattedMessage
                  id="login.heading.phone"
                  defaultMessage="Введіть Ваш номер телефону"
                  description=""
                />
              </p>
              <PhoneAuthForm next={next} />
              {/*
                <p className={s.hint}>
                  <FormattedMessage
                    id="login.phone.privacy.hint1"
                    defaultMessage="зайшовши на сайт Ви автоматично погоджуєтеся з"
                    description=""
                  />{' '}
                  <a href="/privacy" target="_blank">
                    <FormattedMessage
                      id="login.phone.privacy.hint2"
                      defaultMessage="Умовами використання"
                      description=""
                    />
                  </a>
                </p>
              */}
            </div>
          ) : (
            <div>
              <p className={s.lead}>
                <FormattedMessage
                  id="login.heading"
                  defaultMessage="Увійдіть за допомогою Вашого email"
                  description=""
                />
              </p>
              <LoginForm next={next} />
              <strong className={s.lineThrough}>
                <FormattedMessage
                  id="login.OR"
                  defaultMessage="АБО"
                  description=""
                />
              </strong>
              <p>
                <Link to="/register" className={`btn btn-warning ${s.button}`}>
                  <FormattedMessage
                    id="login.register.btn"
                    defaultMessage="Зареєструватися"
                    description=""
                  />
                </Link>
              </p>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default withStyles(s)(Login);
