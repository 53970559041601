import React from 'react';
import PropTypes from 'prop-types';

import CarItem from 'components/CarItem';
import {
  DAY_BEST_SERVICE_SLUG,
  BEST_PRICE_SERVICE_SLUG,
  ALL_INCLUSIVE_SERVICE_SLUG,
  ALL_INCLUSIVE_PRO_SERVICE_SLUG,
} from 'constants/services';

import DayBestItem from 'components/Services/DayBestItem';
import BestPriceItem from 'components/Services/BestPriceItem';

const Preview = props => {
  const { offer, service: { meta } } = props;
  const itemProps = { car: offer, services: [{ properties: meta.properties }] };

  if (
    meta.slug === DAY_BEST_SERVICE_SLUG ||
    meta.slug === ALL_INCLUSIVE_SERVICE_SLUG ||
    meta.slug === ALL_INCLUSIVE_PRO_SERVICE_SLUG
  ) {
    return <DayBestItem {...itemProps} />;
  }

  if (meta.slug === BEST_PRICE_SERVICE_SLUG) {
    return <BestPriceItem {...itemProps} />;
  }

  return <CarItem {...itemProps} />;
};

Preview.propTypes = {
  offer: PropTypes.shape().isRequired,
  service: PropTypes.shape().isRequired,
};

export default Preview;
