import React from 'react';
import Layout from 'components/Layout';
import Order from 'containers/Order/Order';

import { carItemRequest } from 'actions/carsActions';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import {
  fetchServicesRequest,
  setOrderServices,
  setOrderOffer,
} from 'actions/orderActions';
import { makeLocalize } from 'locales';
import messages from './messages';

async function action({ params, fetch, store, intl, query }) {
  const localize = makeLocalize(intl, messages);
  const car = await carItemRequest(fetch, params.id);
  const services = await fetchServicesRequest(fetch);
  store.dispatch(setOrderServices(services));
  store.dispatch(setOrderOffer(car));

  store.dispatch(
    setBreadcrumbs([
      { title: 'Avtoprod', href: '/' },
      { title: car.title, href: `/car/${car.id}` },
      { title: localize('Рекламувати'), active: true },
    ]),
  );

  const title = localize('Прискорення продажу "{car}"', { car: car.title });

  return {
    chunks: ['core'],
    title,
    component: (
      <Layout>
        <Order isFirstOrder={'first' in query} />
      </Layout>
    ),
  };
}

export default action;
