import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import uniqBy from 'lodash/uniqBy';

import { createOrder } from '../../actions/orderActions';
import Order from '../../components/Order';

function mapStateToProps({ order: { services, offer, order } }) {
  return {
    services: sortBy(services, o => parseFloat(o.price)),
    // properties: _.reverse(_.uniqBy(_.reduce(services, (result, item) => _.concat(result, item.meta.properties), []), 'slug')),
    periods: reverse(
      sortBy(
        uniqBy(
          services.map(i => i.period),
          'days',
        ),
        'days',
      ),
    ),
    offer,
    order,
  };
}

export default connect(mapStateToProps, { createOrder })(Order);
