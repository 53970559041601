import { defineMessages } from 'react-intl';

export default defineMessages({
  'послуга надається на': {
    id: 'order:desct:period1',
    defaultMessage: 'послуга надається на',
  },
  днів: {
    id: 'order:desct:period2',
    defaultMessage: 'днів',
  },
  грн: {
    id: 'order:item:currency',
    defaultMessage: 'грн',
  },
  Оголошення: {
    id: 'order:desct:advert',
    defaultMessage: 'Оголошення',
  },
  'Сума до сплати': {
    id: 'order:desct:sum',
    defaultMessage: 'Сума до сплати',
  },
  'рекламна послуга': {
    id: 'order:desct:text1',
    defaultMessage: 'рекламна послуга',
  },
  'рекламні послуги': {
    id: 'order:desct:text2',
    defaultMessage: 'рекламні послуги',
  },
  'рекламних послуг': {
    id: 'order:desct:text3',
    defaultMessage: 'рекламних послуг',
  },
});
