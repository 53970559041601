exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._37axT{text-align:center;font-size:12.8px;font-size:.8rem}.ZLjj4{margin-bottom:15px}._21sSe{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;margin:0;padding:10px 16px;width:100%;outline:0;border-radius:0;text-align:center;text-decoration:none;font-size:18px;line-height:1.3333333;cursor:pointer}._21sSe:focus{-webkit-box-shadow:0 0 8px rgba(0,116,194,.6);box-shadow:0 0 8px rgba(0,116,194,.6)}", ""]);

// exports
exports.locals = {
	"sent_text": "_37axT",
	"formGroup": "ZLjj4",
	"button": "_21sSe"
};