export const invoiceRequest = async (fetch, id) =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: `{
        invoice(id: ${id}) {
          number
          amount
          payed
          description
        }
      }
      `,
    }),
  })
    .then(response => response.json())
    .then(data => data.data.invoice);

export default undefined;
