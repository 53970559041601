import { defineMessages } from 'react-intl';

export default defineMessages({
  Рекламувати: {
    id: 'order:bread:title',
    defaultMessage: 'Рекламувати',
  },
  'Прискорення продажу "{car}"': {
    id: 'order:seo_title',
    defaultMessage: 'Прискорення продажу "{car}"',
  },
});
