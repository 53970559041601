import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// import _ from 'utils';
import { Row, Col, Button } from 'reactstrap';
import Panel from 'components/Helpers/Panel';
import { DEFAULT_PROPOSAL_SLUG } from 'constants/services';
import { FormattedMessage } from 'react-intl';
import { successUrl } from 'routes/add/urls';
import history from '../../history';

import s from './Order.css';
import ProposalItem from './ProposalItem';
import ProposalDescription from './ProposalDescription';
import LiqPay from '../LiqPay';

const DEMO_OFFER_ID = '6886';
const ACTIVE_NO_LIMIT_SERVICE = 'active_no_limit';

const PayButton = props => {
  const { children, ...other } = props;
  return (
    <Button color="success" size="lg" {...other}>
      {children}
    </Button>
  );
};

PayButton.propTypes = {
  children: PropTypes.element.isRequired,
};

const SkipButton = props => (
  <Button color="link" outline {...props}>
    <FormattedMessage
      id="order.without.ads"
      defaultMessage="Розмістити безкоштовно на 2 тижні"
      description=""
    />
  </Button>
);

class Order extends React.Component {
  static contextTypes = { fetch: PropTypes.func.isRequired };

  static propTypes = {
    offer: PropTypes.shape().isRequired,
    services: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    createOrder: PropTypes.func.isRequired,
    order: PropTypes.shape(),
    isFirstOrder: PropTypes.bool,
  };

  static defaultProps = {
    order: null,
    isFirstOrder: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      // period: null,
      service: DEFAULT_PROPOSAL_SLUG,
    };
  }

  // setPeriod = period => {
  //   this.setState({ period });
  // };

  selectService = serviceId => {
    this.setState({ service: serviceId });
  };

  createOrder = serviceId => {
    const { offer, createOrder } = this.props;
    createOrder(this.context.fetch, {
      content_type: 12, // hardcoded for cars
      object_id: offer.id,
      service: serviceId,
    });
  };

  render() {
    const { services, order, offer, isFirstOrder } = this.props;
    const { service } = this.state;
    // const period = this.state.period || _.maxBy(periods, o => o.days).days;
    const activeNoLimitService = services.find(
      i => i.meta.slug === ACTIVE_NO_LIMIT_SERVICE,
    );
    const filteredServices = services.filter(
      i => i.meta.slug !== ACTIVE_NO_LIMIT_SERVICE,
    );
    const selectedService = services.filter(
      item => item.id === service || item.meta.slug === service,
    )[0];

    return (
      <div>
        <Row>
          {/* <Col xs="12" className="mb-3">
            <p className="lead">1. Оберіть послугу:</p>
            <Nav pills>
            {periods.map(item => (
              <NavItem key={item.days}>
                <NavLink
                  className={s.pill}
                  onClick={() => this.setPeriod(item.days)}
                  active={item.days === period}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
            </Nav>
          </Col> */}
          <Col xs="12">
            <Row>
              {filteredServices.map(item => (
                <ProposalItem
                  item={item}
                  key={item.id}
                  choosen={selectedService && item.id === selectedService.id}
                  onClick={this.selectService}
                />
              ))}
            </Row>
          </Col>
        </Row>
        {filteredServices && (
          <Row>
            <Col xs="12" className="mb-3">
              <Panel>
                <Row className="mb-2">
                  <Col xs="12">
                    <p className="h4">{selectedService.meta.title}</p>
                  </Col>
                </Row>
                <ProposalDescription offer={offer} service={selectedService} />
                {offer.id !== DEMO_OFFER_ID && (
                  <>
                    <p className="text-center mt-4">
                      {order ? (
                        <LiqPay {...order.liqpay} autosubmit />
                      ) : (
                        <PayButton
                          className={s.pay_btn}
                          onClick={() => this.createOrder(selectedService.id)}
                        >
                          {isFirstOrder ? (
                            <FormattedMessage
                              id="order.publish.label"
                              defaultMessage="Продати швидко!"
                              description=""
                            />
                          ) : (
                            <FormattedMessage
                              id="order.paybutton.label"
                              defaultMessage="Підключити послугу"
                              description=""
                            />
                          )}
                        </PayButton>
                      )}
                    </p>
                    <p className="text-center">
                      за {parseInt(selectedService.price, 10)} грн.
                    </p>
                  </>
                )}
              </Panel>
            </Col>
          </Row>
        )}
        {isFirstOrder && (
          <Row>
            <Col xs="12">
              <Panel>
                <Row>
                  {activeNoLimitService && (
                    <Col className="text-center" xs={12}>
                      {activeNoLimitService.meta.properties.map(i => (
                        <p key={i.slug} className="small mb-1">
                          {i.title}
                        </p>
                      ))}
                      <Button
                        color="outline-info"
                        className={s.skip_btn}
                        onClick={() =>
                          this.createOrder(activeNoLimitService.id)
                        }
                      >
                        {activeNoLimitService.meta.title} (
                        {parseInt(activeNoLimitService.price, 10)} грн.)
                      </Button>
                    </Col>
                  )}
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    або
                  </Col>
                  <Col className="text-center" xs={12}>
                    <SkipButton
                      className={s.skip_btn}
                      onClick={() => history.push(successUrl(offer))}
                    />
                  </Col>
                </Row>
              </Panel>
              <p className="mt-3 text-center">
                <FormattedMessage
                  id="order.redirect.text"
                  defaultMessage="Зараз Ви будете перенаправлені на сторінку оплати за допомогою LiqPay"
                  description=""
                />
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withStyles(s)(Order);
