// import { emailValidator } from '../../Form/validators';

const validate = (values, { localize }) => {
  const errors = {};
  // const emailErrorMessage = emailValidator(values.email);
  // if (emailErrorMessage) {
  //   errors.email = emailErrorMessage;
  // }

  if (!values.email) {
    errors.email = localize('Будь-ласка, вкажіть Ваш email');
  }

  if (!values.password) {
    errors.password = localize('Будь-ласка, вкажіть пароль');
  }

  return errors;
};

export default validate;
