import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Col } from 'reactstrap';
import cn from 'classnames';
// import _ from 'utils';
import Icon from 'react-icons-kit';
import { check } from 'react-icons-kit/fa/check';
// import { remove } from 'react-icons-kit/fa/remove'
// import { starO } from 'react-icons-kit/fa/starO'
// import { withLocalize } from 'locales';
import Panel from 'components/Helpers/Panel';
import s from './ProposalItem.css';
// import messages from '../messages';

const styles = {
  root: {
    // best_price: {
    //   boxShadow: '0 0 0 0.2rem rgba(40, 167, 69, 0.5)',
    // },
    // maximum: {
    //   boxShadow: '0 0 0.2rem 0.2rem rgba(51, 51, 51, 0.2)',
    // }
  },
  heading: {
    // mark_with_discount: {
    //   backgroundColor: '#d1ecf1',
    //   color: '#0c5460',
    // },
    // day_best: {
    //   backgroundColor: '#fff3cd',
    //   color: '#856404',
    // },
    maximum: {
      // backgroundColor: '#004086',
      backgroundColor: '#333',
      color: '#fff',
    },
    maximum_pro: {
      backgroundColor: 'rgba(48, 25, 52, 0.85)',
      color: '#fff',
    },
  },
};

const COMPARED_PRICE_MAP = {
  // best_price: 199,
  // maximum: 298,
  // maximum_pro: 399,
};

const ECONOMY_TEXT_MAP = {
  day_best: 'на 15% більше переглядів',
  best_price: 'на 15% більше переглядів',
  maximum: 'на 50% більше переглядів',
  maximum_pro: 'на 70% більше переглядів',
};

const ProposalItem = ({ item, choosen = false, onClick }) => {
  const { meta, price, period, id } = item;
  const comparedPrice = COMPARED_PRICE_MAP[meta.slug];
  const economyText = ECONOMY_TEXT_MAP[meta.slug];

  return (
    <Col xs="12" md="4">
      <Panel
        className={`${s.root} ${choosen && s.choosen}`}
        style={styles.root[meta.slug] || {}}
        onClick={() => onClick(id)}
      >
        <div
          className={`h4 ${s.header}`}
          style={styles.heading[meta.slug] || {}}
        >
          {choosen && (
            <Icon className={s.choosen_icon} icon={check} size={24} />
          )}{' '}
          {meta.title}
        </div>
        {/* <div className={s.properties}>
            {this.renderProperties()}
          </div> */}
        <div className={s.title}>
          {comparedPrice &&
            comparedPrice !== parseInt(price, 10) && (
              <p className={s.comparedPrice}>
                {parseInt(comparedPrice, 10)} грн{' '}
              </p>
            )}
          {parseInt(price, 10)} грн{' '}
          <small className={cn('text-muted', s.small)}>
            / {period.days > 360 ? 'до продажу' : period.title}
          </small>
          {economyText && <p className={s.benefit}>{economyText}</p>}
        </div>
      </Panel>
    </Col>
  );
};

ProposalItem.propTypes = {
  item: PropTypes.shape().isRequired,
  choosen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  // properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ProposalItem.defaultProps = {
  choosen: false,
};

export default withStyles(s)(ProposalItem);
