import React from 'react';
import Layout from 'components/Layout';
import Page from 'components/Page';
import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import { authActivateRequest } from 'actions/authActions';
import { getPrefix } from 'locales';

import successUk from './success.md';
import successRu from './success.ru.md';
import failUk from './fail.md';
import failRu from './fail.ru.md';

async function action({ store, fetch, params, intl }) {
  const activation = await authActivateRequest(fetch, params.key);
  const { success, fail } =
    getPrefix(intl.locale) === 'uk'
      ? { success: successUk, fail: failUk }
      : { success: successRu, fail: failRu };

  const page = activation !== 'ok' ? fail : success;

  store.dispatch(
    setBreadcrumbs([
      { title: 'Avtoprod', href: '/' },
      { title: page.title, active: true },
    ]),
  );

  return {
    title: `AvtoProd - ${page.title}`,
    chunks: ['core'],
    component: (
      <Layout>
        <Page {...page} />
      </Layout>
    ),
  };
}

export default action;
