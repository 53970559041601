exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IJtQ{margin-top:5px}._3Vy51{position:relative;display:inline-block;padding:3px 16px;color:#fff;background-color:#1bc744;font-weight:700;font-size:13px;text-transform:uppercase}", ""]);

// exports
exports.locals = {
	"root": "_1IJtQ",
	"title": "_3Vy51"
};