exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Roboto\",sans-serif;--link-color:#333;--link-color-ligther:rgba(51,51,51,.7);--link-hover-color:rgba(27,199,68,.9);--ap-orange-color:#ffaa4d;--text-white-stroke-shadow:-1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;--text-black-stroke-shadow:-1px -1px 0 #333,1px -1px 0 #333,-1px 1px 0 #333,1px 1px 0 #333;--max-content-width:1400px;--backgroud-body:#f9f9f9;--border-main-style:1px solid #eee;--main-box-shadow:0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.08);--item-box-shadow:0 0 4px 1px rgba(0,0,0,.08);--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--list-item-label-color:#1bc744;--price-background-color:#1bc744;--service-box-shadow:0 0 3.2px 3.2px rgba(51,51,51,.2);--service-box-shadow:0 0 0.2rem 0.2rem rgba(51,51,51,.2)}.FKoM9{padding:10px!important;background:hsla(0,0%,94%,.4)!important;cursor:pointer}._2VLXm{background-color:#fff!important}._22xWP{color:rgba(27,199,68,.9);color:var(--link-hover-color)}._22H6h{padding:16px 0;padding:1rem 0;background-color:rgba(255,170,77,.75);color:#fff}._2fU0P,._22H6h{text-align:center}._2fU0P{padding:3.2px 16px;padding:.2rem 1rem;margin-bottom:16px;margin-bottom:1rem;font-size:25.6px;font-size:1.6rem}._1etTp{text-decoration:line-through;font-size:16px;font-size:1rem}._1etTp,._2GdpJ{color:darkred;margin:0}._2GdpJ{font-size:14.4px;font-size:.9rem;font-weight:300}._23WGY{font-size:16px;font-size:1rem}", ""]);

// exports
exports.locals = {
	"root": "FKoM9",
	"choosen": "_2VLXm",
	"choosen_icon": "_22xWP",
	"header": "_22H6h",
	"title": "_2fU0P",
	"comparedPrice": "_1etTp",
	"benefit": "_2GdpJ",
	"small": "_23WGY"
};