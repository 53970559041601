import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withLocalize } from 'locales';

import { renderField } from '../../AddCarForm/renderers';

import validate from './validate';
import s from './LoginForm.css';
import messages from './messages';

class LoginForm extends React.Component {
  static contextTypes = {
    fetch: PropTypes.func.isRequired,
  };

  static propTypes = {
    authLogin: PropTypes.func.isRequired,
    next: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    auth: PropTypes.shape().isRequired,
    ctaTitle: PropTypes.string,
    localize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ctaTitle: null,
  };

  submit = values => {
    const { email, password } = values;
    this.props.authLogin(
      this.context.fetch,
      {
        username: email,
        password,
      },
      this.props.next,
    );
  };

  renderErrors = () => {
    const { auth } = this.props;
    if (auth.errors && auth.errors.non_field_errors) {
      return auth.errors.non_field_errors.map(err => (
        <div className="alert alert-danger" key={err}>
          {err}
        </div>
      ));
    }
    return null;
  };

  render() {
    const { handleSubmit, ctaTitle, localize } = this.props;
    return (
      <div>
        <form
          method="post"
          onSubmit={handleSubmit(this.submit)}
          autoComplete="on"
          name="loginForm"
        >
          {this.renderErrors()}
          <Field
            placeholder={localize('Ваш Email')}
            type="text"
            name="email"
            component={renderField}
          />
          <Field
            placeholder={localize('Пароль')}
            type="password"
            name="password"
            component={renderField}
          />
          <div className={s.formGroup}>
            <button className={`btn btn-primary ${s.button}`} type="submit">
              {ctaTitle || localize('Увійти')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withLocalize(messages)(
  withStyles(s)(
    reduxForm({
      form: 'login',
      validate,
    })(LoginForm),
  ),
);
