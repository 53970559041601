import React from 'react';
import Layout from 'components/Layout';
import Login from 'components/Login';
import { makeLocalize } from 'locales';

import { setBreadcrumbs } from 'actions/app/breadcrumbs';
import messages from './messages';

function action({ query, store, intl }) {
  const localize = makeLocalize(intl, messages);
  const title = localize('Вхід');
  const next = query.next || '/';

  store.dispatch(
    setBreadcrumbs([{ title: 'Avtoprod', href: '/' }, { title, active: true }]),
  );

  return {
    chunks: ['core'],
    title,
    component: (
      <Layout>
        <Login title={title} next={next} />
      </Layout>
    ),
  };
}

export default action;
